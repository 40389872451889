<script setup lang="ts">
import type { ContactFormSettingsKey } from "../../form-settings/form-experience/form-settings-keys.js";
import { UserMarketoInputName, type MarketoVals } from "../../types/definitions/inputs/marketo-input-names-and-values.js";
import { MarketoAllInputMeta, type MarketoUserInputMeta } from "../../types/definitions/inputs/marketo-input-meta.js";
import { DropdownInputName, getFormDropdownOptionKeys } from "../../types/definitions/inputs/select-box-fields.js";
import type { FormWidgetData } from "../../../widgetDataTs.js";
import {
  useFormServices,
  useInputsMetaData,
  useInputState,
  useFormSubmit,
  type InputStateOption,
  useEclipseHtmlFormElement,
  useTranslation
} from "../../composables/use-form-services.js";
import { useFormUserInputValues } from "../../composables/use-form-user-input-values.js";

import eclipseInput from "../eclipse-input.vue";
import stateOrTerritorySelector from "../state-or-territory-selector.vue";
import emailOptInCheckbox from "../email-opt-in-checkbox.vue";
import submitButton from '../form-experience/submit-button.vue';

import { TranslationNamespaceEnum } from "../../locale/settings/translation-namespaces.js";
import type { SubmitButtonTranslationKey } from "../../locale/translation-keys/submit-button-text-keys.js";
import partnerOptInCheckboxVue from "../partner-opt-in-checkbox.vue";
import { isNil, type Nil } from "@mcwd/typescript-type-guards";
import { ref, watch, computed } from "vue";
import loaderDark from "../form-experience/loader-icon-dark.vue";
import formLoadError from "./form-load-error.vue";
import { useFormLogger } from "../../composables/use-form-logger.js";
import { GetComputedProperties, type CustomData } from "../../composables/use-custom-data-values.js";
import { useValidations, type ValidatorFn } from "../../composables/use-input-validation.js";

const props = defineProps<{
  formSettingsKey: ContactFormSettingsKey;
  widgetData: FormWidgetData<"ContactForm">;
  customData?: CustomData;
  isInModal?: boolean;
}>();

const { logger } = useFormLogger();

const { formHeading, ctaButtonText, eventType } = GetComputedProperties(props.customData);

const { FormSettings, FormClasses, FormState } = useFormServices<"MarketoForm", "event-registration">({ callerLabel: "event-registration-form-base.vue" });

const{ validateGenericBusinessEmail, validatePhoneNumber, validateDisposableEmailDomains } = useValidations();
const eventTypeToValidation :{[eventType: string] : ValidatorFn} = {
  'Learn': validateDisposableEmailDomains,
  'Evaulate': validateGenericBusinessEmail,
  'Lunch and Learn': validateGenericBusinessEmail,
  'Webinar': validateGenericBusinessEmail
}

if (!("FullGate" in FormSettings.enabledFields)) {
  throw new Error(`Full Gate does not exist for formExperience '${FormSettings.formSettingsKey}'`);
}
const enabledFields = FormSettings.enabledFields.FullGate satisfies readonly string[];
type EnabledField = typeof enabledFields[number];

const { FormInputsMeta } = useInputsMetaData({ callerLabel: "event-registration-form-base.vue" }) as { FormInputsMeta: Pick<MarketoAllInputMeta, EnabledField> };
//this is a hack to get a different translation
const NonBusinessEmail =
  {
    marketoName: "Email",
    name: "eclipse_NonBusinessEmail",
    type: "email",
    isMarketoInput: true,
  } as unknown as MarketoUserInputMeta;

function getEnabledUserFields() {
  return enabledFields.filter(f => (UserMarketoInputName as readonly string[]).includes(f)) as Extract<EnabledField, UserMarketoInputName>[];
}

// Currently only handles marketo field names
const anyEnabledUserField = getEnabledUserFields();
type AnyEnabledUserField = typeof anyEnabledUserField[number];

type SelectFieldName = Extract<EnabledField, DropdownInputName>;
const FormOptions = getFormDropdownOptionKeys(enabledFields.filter(f => (DropdownInputName as readonly string[]).includes(f)) as SelectFieldName[]);

type UserMarketoValues = { [Key in AnyEnabledUserField]: Required<MarketoVals>[Key] };

const { userInputValues, setFormUserInputValuesFromSettings } = useFormUserInputValues<AnyEnabledUserField, UserMarketoValues>({ callerLabel: "event registration" });
setFormUserInputValuesFromSettings();

const { setInputStates, inputStates } = useInputState<AnyEnabledUserField>({ callerLabel: "event-registration-form-base.vue" });
setInputStates(userInputValues, {
  "FirstName": { required: true },
  "LastName": { required: true },
  "Email": {
    required: true,
    validator: eventTypeToValidation[eventType.value] ?? validateGenericBusinessEmail
  },
  "Company": { required: true },
  "Country": { required: true },
  "State": { required: computed(() => { return userInputValues.Country === "United States"; })},
  "Phone": {
    required: true,
    validator: validatePhoneNumber,
  },
  "Tier__c": { required: true },
  "Industry": { required: true },
  "Email_Opt_In__c": { required: false },
  "Partner_Opt_in_Processing__c": { required: false }
} satisfies Record<AnyEnabledUserField, InputStateOption>);

const { submitForm } = useFormSubmit({ callerLabel: "event-registration-form-base.vue" });
const { translate } = useTranslation();
const buttonTextMapKey: SubmitButtonTranslationKey<typeof FormSettings.formSettingsKey> = `${FormSettings.formSettingsKey}-Submit-Button`;

const formError = window.formLoadError;

const { setEclipseHtmlFormElement } = useEclipseHtmlFormElement({ callerLabel: "event-registration-base-form.vue" });
const formElRef = ref<HTMLFormElement | Nil>(null);

watch(() => formElRef.value, (formEl) => {
  if (!isNil(formEl)) {
    logger.debug({ formEl });
    if (!isNil(formEl)) {
      setEclipseHtmlFormElement(formEl);
    }
  }
});

</script>

<template>
  <template v-if="formError">
    <formLoadError />
  </template>

  <template v-else-if="FormState === 'Loading' || FormState === 'Setup'">
    <div class="form-loading">
      <loaderDark />
      <p> Loading Content</p>
    </div>
  </template>

  <template v-else-if="FormState === 'RegularForm'">
    <h3 class="form-header">
      {{ formHeading ? formHeading : translate("events-registration-Heading", { ns: TranslationNamespaceEnum.formHeadingText }) }}
    </h3>

    <form ref="formElRef" class="eclipse-form" :class="FormClasses.marketoFormClass" v-bind="$attrs">
      <eclipseInput v-model="userInputValues.FirstName" :input-state="inputStates.FirstName"
        :form-input-meta="FormInputsMeta.FirstName" />

      <eclipseInput v-model="userInputValues.LastName" :input-state="inputStates.LastName"
        :form-input-meta="FormInputsMeta.LastName" />

      <eclipseInput v-model="userInputValues.Email" :input-state="inputStates.Email"
        :form-input-meta= "eventType == 'Learn' ?  NonBusinessEmail : FormInputsMeta.Email" />

      <eclipseInput v-model="userInputValues.Company" :input-state="inputStates.Company"
        :form-input-meta="FormInputsMeta.Company" />

      <eclipseInput v-model="userInputValues.Country" :input-state="inputStates.Country"
        :form-input-meta="FormInputsMeta.Country" :dropdown-option-keys="FormOptions.Country"
        dropdown-translations-namespace="countries" />

      <stateOrTerritorySelector v-model="userInputValues.State"
        :input-state="inputStates.State"
        :form-input-meta="FormInputsMeta.State"
        :dropdown-option-keys="FormOptions.State"
        dropdown-translations-namespace="states"
      />

      <eclipseInput v-model="userInputValues.Phone" :input-state="inputStates.Phone"
        :form-input-meta="FormInputsMeta.Phone" />

      <eclipseInput v-model="userInputValues.Tier__c" :input-state="inputStates.Tier__c"
        :form-input-meta="FormInputsMeta.Tier__c" :dropdown-option-keys="FormOptions.Tier__c"
        dropdown-translations-namespace="company-sizes" />

      <eclipseInput v-model="userInputValues.Industry" :input-state="inputStates.Industry"
        :form-input-meta="FormInputsMeta.Industry" :dropdown-option-keys="FormOptions.Industry"
        dropdown-translations-namespace="industries" />

      <emailOptInCheckbox v-model="userInputValues.Email_Opt_In__c" :form-input-meta="FormInputsMeta.Email_Opt_In__c"
        :input-state="inputStates.Email_Opt_In__c" :marketo-input-name="FormInputsMeta.Email_Opt_In__c.marketoName" />

      <partnerOptInCheckboxVue v-if="customData?.enabledPartnerOptInCheckBox"
        v-model="userInputValues.Partner_Opt_in_Processing__c"
        :form-input-meta="FormInputsMeta.Partner_Opt_in_Processing__c"
        :input-state="inputStates.Partner_Opt_in_Processing__c"
        :marketo-input-name="FormInputsMeta.Partner_Opt_in_Processing__c.marketoName" />

      <!-- Eclipse Submit Button -->
      <submitButton @submit-form="submitForm()">
        {{ ctaButtonText ? ctaButtonText : translate(buttonTextMapKey, { ns: TranslationNamespaceEnum.submitButtonText }) }}
      </submitButton>
    </form>
  </template>
</template>
